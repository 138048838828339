/**
* @file
* All grid variables should be defined here
*/
$enable-flex: true;
$enable-grid-classes: true;

/**
* Max size, this is for content which bleeds off the screen until this size
 */
$max-width: 1680px;

/**
* Grid breakpoints
* Define the minimum dimensions at which your layout will change,
* adapting to different screen sizes, for use in media queries.
 */
$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 1024px,
    lg: 1440px,
    xl: $max-width
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

/**
Grid containers
* Define the maximum width of `.container` for different screen sizes.
* These maximum widths should include the padding on both sides, so the gutter-width * 2
 */
$container-max-widths: (
    sm: 720px,
    md: 1024px,
    lg: 1140px
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

/**
* Grid columns
* Set the number of columns and specify the width of the gutters.
 */
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

/* Offset variables */
$offset: 15px;
$offset--sm: 10px;

$grid-offsets: (
    xs: $offset,
    sm: $offset--sm,
    md: $offset
) !default;

/* Gutter variables */
$gutter: 10px;
$gutter--sm: 20px;
$gutter--md: 30px;

$grid-gutter-widths: (
    xs: $gutter,
    sm: $gutter--sm,
    md: $gutter--md
) !default;
