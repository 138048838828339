/**
* @file
* All colors used on the website are defined here
*/

/* Base colors */
$color-black: #111111 !default;
$color-white: #ffffff !default;
$color-yellow: #ffdd02 !default;
$color-blue: #006eb8 !default;
$color-green: #01967f !default;
$color-red: #e90027 !default;
$color-orange: #e95a00 !default;
$color-purple: #9265a8 !default;
$color-gold: #b48724 !default;

/* Grey colors */
$color-grey: #666666 !default;
$color-grey--light: #b3b3b3 !default;
$color-grey--lighter: #d9d9d9 !default;
$color-grey--lightest: #f2f2f2 !default;
$color-grey--dark: #1a1a1a !default;

/* Parsley colors */
$field-success-color: $color-green !default;
$field-error-color: $color-red !default;

/* Gradients */
$gradient-yellow-red: linear-gradient(90deg, $color-yellow, $color-red) !default;
$gradient-yellow-blue: linear-gradient(90deg, $color-yellow, $color-gold) !default;
$gradient-yellow-green: linear-gradient(90deg, $color-yellow, $color-green) !default;
$gradient-yellow-purple: linear-gradient(90deg, $color-yellow, $color-purple) !default;
$gradient-white-transparant: linear-gradient(-180deg, rgba($color-white, 0) 0%, $color-white 100%) !default;
$gradient-dark-grey-black: linear-gradient(-90deg, #262626 1%, #000000 100%) !default;
$gradient-transparent-grey: linear-gradient(90deg, rgba(237, 237, 237, 0) 0%, #ededed 100%) !default;

/* vendor colors */
$color-social--facebook: #3b5998 !default;
$color-social--twitter: #55acee !default;
$color-social--linkedin: #007bb5 !default;
