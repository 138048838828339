.c-subscription-card__wrapper {
    display: flex;
    flex-direction: column;
    margin: 4rem 0;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.c-subscription-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28rem;
    margin: 0 2rem 3rem;
    padding: 2.6rem 1.8rem 1.8rem;
    background-color: $color-white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        height: 43rem;
    }

    &::before {
        content: '';
        position: absolute;
        height: 8px;
        top: 0px;
        left: 0;
        right: 0;
    }

    &:nth-child(1)::before {
        background-color: $color-green;
    }

    &:nth-child(2)::before {
        background-color: #eb4293;
    }

    &:nth-child(3)::before {
        background-color: $color-orange;
    }

    .c-toggle-slider {
        margin-bottom: 1.8rem;
    }

    .c-button {
        font-size: 2.2rem;
    }
}

.c-subscription-card__title {
    margin-bottom: 1.8rem;

    h3 {
        font-family: $ff-serif;
        font-size: 4rem;
        font-weight: 500;
    }
}
.c-subscription-card__usps {
    width: 100%;
    margin-bottom: 3rem;
    color: $color-grey--dark;

    li {
        position: relative;
        padding-left: 2.6rem;
        margin-bottom: 1.2rem;
        font-size: 1.8rem;

        &::before {
            content: '';
            position: absolute;
            background-image: url('../../../images/agconnect-new/icons/checkmark--blue.svg');
            background-size: 1.6rem;
            background-repeat: no-repeat;
            left: 3px;
            top: 1px;
            height: 1.6rem;
            width: 1.6rem;
        }
    }
}

.c-subscription-card__price-block {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 3rem;
}

.c-subscription-card__prices {
    position: relative;
}

.c-subscription-card__previous-price {
    position: absolute;
    left: 0;
    transform: translateX(calc(-100% - 6px));
    top: 0;
    text-align: right;
    font-family: $ff-serif;
    font-size: 2.4rem;
    text-decoration: line-through;
    color: $color-grey;
}

.c-subscription-card__current-price {
    position: relative;
    font-family: $ff-serif;
    font-size: 3.6rem;
    font-weight: 500;
}

.c-subscription-card__price-suffix {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 400;
    color: $color-grey--dark;
}
