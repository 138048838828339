/**
* @file
* Basic styling for select boxes
*
* @example
* <select name="select" id="select" class="c-select">
*   <option value="">Selecteer je functie</option>
*   <option value="1">Management</option>
*   <option value="2">Development</option>
*   <option value="3">Creative</option>
* </select>
 */

.c-select {
  @extend .c-select__input;
}

