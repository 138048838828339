.c-password-reset {
    display: flex;
    justify-content: flex-end;
}


.c-password-reset__link {
    display: inline-block;
    padding-top: 1rem;
}
