@use 'sass:list';

// Margin
@mixin mt($unit) {
    margin-top: spacer($unit);
}

@mixin mr($unit) {
    margin-right: spacer($unit);
}

@mixin mb($unit) {
    margin-bottom: spacer($unit);
}

@mixin ml($unit) {
    margin-left: spacer($unit);
}

@mixin mx($unit) {
    margin-right: spacer($unit);
    margin-left: spacer($unit);
}

@mixin my($unit) {
    margin-top: spacer($unit);
    margin-bottom: spacer($unit);
}

@mixin ma($unit) {
    margin: spacer($unit);
}

// Padding
@mixin pt($unit) {
    padding-top: spacer($unit);
}

@mixin pr($unit) {
    padding-right: spacer($unit);
}

@mixin pb($unit) {
    padding-bottom: spacer($unit);
}

@mixin pl($unit) {
    padding-left: spacer($unit);
}

@mixin px($unit) {
    padding-right: spacer($unit);
    padding-left: spacer($unit);
}

@mixin py($unit) {
    padding-top: spacer($unit);
    padding-bottom: spacer($unit);
}

@mixin pa($unit) {
    padding: spacer($unit);
}

// Extend outside current column
@mixin extend-left($columns) {
    position: relative;

    @each $breakpoint, $values in $grid {
        @include media-breakpoint-up($breakpoint) {
            left: calc(get-offset-of-columns($columns, $breakpoint) * -1);
        }
    }
}
