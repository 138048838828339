.c-pill {
    @include pill-text;
    height: 2rem;
    padding: 1px 8px 1px;
    border-radius: 20px;

    * + & {
        margin-left: 0.4rem;
    }

    &--pro {
        color: $c-white;
        background-color: $c-main;
    }

    &--partner {
        color: $c-white;
        background-color: $c-partner;
    }

    &--personalized {
        color: $c-white;
        background-color: $c-cta;
    }

    &--video {
        color: $c-main;
        background-color: $c-white;

        &::before {
            content: '\25B6';
            font-size: 10px;
            margin-right: 4px;
        }
    }

    &--audio {
        color: $c-main;
        background-color: $c-white;

        &::before {
            content: '\1F3A4';
            font-size: 10px;
            margin-right: 4px;
        }
    }
}
