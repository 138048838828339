@mixin article-title {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 4.8rem;
    color: $c-text;

    @include media-breakpoint-up('md') {
        font-size: 4.8rem;
        line-height: 5.6rem;
    }
}

@mixin heading($size) {
    @if ($size != xl and $size != lg and $size != md and $size != sm) {
        @error "Heading size must be either 'xl', 'lg', 'md' or 'sm'. Size received: #{$size}.";
    }

    display: block;
    font-family: $ff-sans;
    font-style: normal;
    font-weight: 600;
    color: $c-text;
    letter-spacing: 0.005em;

    @if ($size == xl) {
        font-size: 2.8rem;
        line-height: 3.6rem;
        margin-bottom: 1.2rem;

        @include media-breakpoint-up('md') {
            font-size: 3.2rem;
            margin-bottom: 1.6rem;
        }
    }

    @if ($size == lg) {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;

        @include media-breakpoint-up('md') {
            font-size: 2.6rem;
            line-height: 3rem;
            margin-bottom: 1.6rem;
        }
    }

    @if ($size == md) {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 0.8rem;

        @include media-breakpoint-up('md') {
            font-size: 2.2rem;
            line-height: 2.6rem;
            margin-bottom: 1.2rem;
        }
    }

    @if ($size == sm) {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 0.4rem;

        @include media-breakpoint-up('md') {
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 0.8rem;
        }
    }
}

@mixin paragraph($size) {
    @if ($size != lg and $size != md and $size != sm) {
        @error "Paragraph size must be either 'lg', 'md' or 'sm'. Size received: #{$size}.";
    }

    font-family: $ff-serif;
    font-style: normal;
    font-weight: 400;
    color: $c-body-text;

    @if ($size == lg) {
        font-size: 1.6rem;
        line-height: 2.4rem;

        @include media-breakpoint-up('md') {
            font-size: 1.8rem;
            line-height: 3rem;
        }
    }

    @if ($size == md) {
        font-size: 1.4rem;
        line-height: 2rem;

        @include media-breakpoint-up('md') {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }

    @if ($size == sm) {
        font-size: 1.2rem;
        line-height: 1.8rem;

        @include media-breakpoint-up('md') {
            font-size: 1.4rem;
            line-height: 2.2rem;
        }
    }
}

@mixin intro($size) {
    @if ($size != xl and $size != lg and $size != md and $size != sm) {
        @error "Intro size must be either 'lg', 'md' or 'sm'. Size received: #{$size}.";
    }

    font-family: $ff-sans;
    font-style: normal;
    font-weight: 400;
    color: $c-text;

    @if ($size == xl) {
        font-size: 2rem;
        line-height: 2.8rem;

        @include media-breakpoint-up('md') {
            font-size: 2.2rem;
            line-height: 3rem;
        }
    }

    @if ($size == lg) {
        font-size: 1.6rem;
        line-height: 2.4rem;

        @include media-breakpoint-up('md') {
            font-size: 1.8rem;
            line-height: 3rem;
        }
    }

    @if ($size == md) {
        font-size: 1.4rem;
        line-height: 2.2rem;

        @include media-breakpoint-up('md') {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }

    @if ($size == sm) {
        font-size: 1.2rem;
        line-height: 2rem;

        @include media-breakpoint-up('md') {
            font-size: 1.4rem;
            line-height: 2.2rem;
        }
    }
}

@mixin label($size, $weight: false) {
    @if ($size != lg and $size != md and $size != sm) {
        @error "Label size must be either 'lg', 'md' or 'sm'. Size received: #{$size}.";
    }

    @if ($weight != bold and $weight) {
        @error "Label weight must be either 'bold' or empty. Weight received: #{$weight}.";
    }

    font-family: $ff-sans;
    font-style: normal;
    font-weight: 400;

    @if ($size == lg) {
        font-size: 1.2rem;
        line-height: 1.8rem;

        @include media-breakpoint-up('md') {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
    }

    @if ($size == md) {
        font-size: 0.8rem;
        line-height: 1.2rem;

        @include media-breakpoint-up('md') {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    @if ($size == sm) {
        font-size: 1rem;
        line-height: 1.6rem;
    }

    @if ($weight) {
        font-weight: 700;
    }
}

@mixin pill-text() {
    font-family: $ff-sans, sans-serif;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-transform: uppercase;
}

@mixin button-text() {
    font-family: $ff-sans, sans-serif;
    font-size: 1.2rem;
    line-height: 2.8rem;
    letter-spacing: 0.12em;
    font-weight: 600;
}

@mixin meta-menu-link() {
    display: block;
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2.8rem;
    letter-spacing: 0.015em;
    color: $c-white;
    white-space: nowrap;
    text-decoration: none;
}

@mixin main-menu-link() {
    display: block;
    font-family: $ff-sans;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.8rem;
    letter-spacing: 0.015em;
    color: $c-text;
    white-space: nowrap;
    text-decoration: none;
}

@mixin search-input() {
    font-family: $ff-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: $c-text;
}

@mixin section() {
    font-family: $ff-sans;
    font-size: 1.2rem;
    line-height: 2.8rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
}

@mixin menu-link($size, $weight: 'medium') {
    @if ($size != md and $size != sm) {
        @error "Menu link size must be either 'md' or 'sm'. Size received: #{$size}.";
    }

    @if ($weight != medium and $weight != regular) {
        @error "Menu link weight must be either 'medium' or 'regular. Weight received: #{$weight}.";
    }

    font-family: $ff-sans;

    @if ($size == md) {
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 500;
        text-decoration: none;
    }

    @if ($size == sm) {
        font-size: 1.2rem;

        @if ($weight == medium) {
            line-height: 2.4rem;
            font-weight: 500;
            text-decoration: underline;
        }

        @if ($weight == regular) {
            line-height: 1.8rem;
            font-weight: 400;
            text-decoration: none;
        }
    }
}

@mixin quote($size) {
    @if ($size != xl and $size != lg and $size != md and $size != sm) {
        @error "Quote size must be either 'xl', 'lg', 'md' or 'sm'. Size received: #{$size}.";
    }

    font-family: $ff-serif;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0.012em;

    @if ($size == xl) {
        font-size: 3.6rem;
        line-height: 4.8rem;
    }

    @if ($size == lg) {
        font-size: 2rem;
        line-height: 2.6rem;

        @include media-breakpoint-up(md) {
            font-size: 3.2rem;
            line-height: 4.2rem;
        }
    }

    @if ($size == md) {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }

    @if ($size == sm) {
        font-size: 1.6rem;
        line-height: 2.4rem;

        @include media-breakpoint-up(md) {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }
}

@mixin quote-attribution() {
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.008em;
}

@mixin teaserNumber() {
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.005em;
    color: $c-disabled;

    @include media-breakpoint-up(md) {
        font-size: 48px;
        line-height: 56px;
    }
}
