.c-ag-background {
    margin-top: -2rem;

    @include media-breakpoint-up(sm) {
        margin-top: -4rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: -6rem;
    }
}

.c-ag-background__top,
.c-ag-background__bottom {
    background-size: 2000px;
    background-repeat: no-repeat;
}

.c-ag-background__top {
    background-color: $color-white;
    background-image: url('../../../images/agconnect/ag-background--stroke.svg');
    background-position: bottom -1050px center;
}

.c-ag-background__bottom {
    background-color: $color-blue--lighter;
    background-color: $color-blue--lighter;
    background-image: url('../../../images/agconnect/ag-background--fill.svg');
    background-position: top -375px center;
}
