.c-membership-type-block__content {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
  }
}

.c-membership-type-block__item {
  display: flex;
  flex-flow: row nowrap;
}
