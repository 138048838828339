.c-textarea {
    @include rectangularInputBase;

    &__input {
        @include py(2);
        height: auto;
        color: $c-body-text;
        background-color: $c-page-background;
    }
}
