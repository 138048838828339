/**
* @file
* Basic styling for input fields & text areas
*
* @example
* <input type="text" class="c-form-control" />
* <textarea type="text" class="c-form-control"></textarea>
 */

::placeholder {
  color: $color-grey--light;
}

.c-form-control {
  @extend .c-text-input__input;
}

