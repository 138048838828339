.c-dashboard {
  background: $color-white;
  padding: 2.5rem 2.5rem;
  border: 1px solid $color-grey--lighter;
}

.c-dashboard__header {
  font-size: 2rem;
  text-align: center;
}

.c-dashboard__section {
  color: $color-grey--lighter;
  padding: 2em 0 1em 0;
  border-bottom: 1px solid $color-grey--lighter;
  font-size: 1.5rem;
}

.c-dashboard__item {
  padding: 1em 0;
  border-bottom: 1px solid $color-grey--lighter;
}

.c-dashboard__link {
  display: block;
  color: $color-black;
  position: relative;
  text-decoration: none;

  &:after {
    @extend %icon;
    content: icon-char(arrow--right);
    font-size: 1rem;
    margin-left: .5rem;
    position: absolute;
    right: 0.2rem;
    top: 0.8rem;
  }
}

.c-dashboard__link i {
  display: inline-block;
  width: 2em;
}
