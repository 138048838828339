$c-main: #005fee;
$c-cta: #ff6c00;
$c-partner: #038f00;
$c-white: #ffffff;

$c-text: #3f3d48;
$c-body-text: #000000;
$c-page-background: #f2f0ee;
$c-section-background: $c-white;

$c-support: #7688a3;
$c-disabled: #c7cbd1;
$c-error: #ff008a;

$c-primary-button-background: $c-main;
$c-link: $c-main;

// Deprecated colors
$c-transparent: rgba(0, 0, 0, 0);
$c-black: #000000;
$c-green: #080;
$c-red: #800;

$c-accountwall-background: $c-white;
$c-paywall-background: $c-white;

$c-primary-button: $c-white;

$c-alert-background: $c-white;
$c-alert-success: $c-green;
$c-alert-error: $c-red;
$c-alert-error-background: lighten($c-red, 70%);
