/**
* @file
* All colors used on the website are defined here
*/

/* Base colors */
$color-black: #1a1a1a;
$color-white: #ffffff;
$color-blue: #00ace3;
$color-pink: #eb4293;
$color-green: #abcc3d;
$color-orange: #ff6e2b;
$color-yellow: #f2cc0c;
$color-red: #ff2b2b;

/* Secondary colors */
$color-blue--dark: #005085;
$color-blue--light: #80d6f1;
$color-blue--lighter: #a6e2f5;
$color-blue--lightest: #d9f3fb;
$color-purple: #9265a8;
$color-pink: #feebee;
$color-orange--light: lighten($color-orange, 15%);

/* Grey colors */
$color-grey: #bdbdbd;
$color-grey--light: #dedede;
$color-grey--lighter: #e8e8e8;
$color-grey--dark: #838383;

/* Gradients */
$gradient-yellow-red: linear-gradient(90deg, $color-yellow, $color-red);
$gradient-yellow-blue: linear-gradient(90deg, $color-yellow, $color-blue);
$gradient-yellow-green: linear-gradient(90deg, $color-yellow, $color-green);
$gradient-yellow-purple: linear-gradient(90deg, $color-yellow, $color-purple);
$gradient-white-transparant: linear-gradient(-180deg, rgba($color-white, 0) 0%, $color-white 100%);
$gradient-dark-grey-black: linear-gradient(-90deg, #262626 1%, #000000 100%);
$gradient-transparent-grey: linear-gradient(90deg, rgba(237, 237, 237, 0) 0%, #ededed 100%);

/* vendor colors */
$color-social--facebook: #3b5998;
$color-social--twitter: #55acee;
$color-social--linkedin: #007bb5;

/* Shadows */
$box-shadow-color: rgba(0, 0, 0, 0.25);
$box-shadow: 2px 2px 0 0 $box-shadow-color;
$box-shadow--focus: 2px 2px 0 0 $color-blue;
$box-shadow--error: 2px 2px 0 0 $color-red;
