/**
* @file
* Fonts and font variables should be defined here
*/

/*
* Futura LT
*/

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_book.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_extra_bold.otf') format('opentype');
    font-weight: 800;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_heavy.otf') format('opentype');
    font-weight: 900;
}

/* Futura oblique */

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_light.otf') format('opentype');
    font-weight: 300;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_book.otf') format('opentype');
    font-weight: 400;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_medium.otf') format('opentype');
    font-weight: 500;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_bold.otf') format('opentype');
    font-weight: 700;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_extra_bold.otf') format('opentype');
    font-weight: 800;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_oblique_heavy.otf') format('opentype');
    font-weight: 900;
    font-style: oblique;
}

/* Futura condensed */

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_light.otf') format('opentype');
    font-weight: 300;
    font-stretch: condensed;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_medium.otf') format('opentype');
    font-weight: 500;
    font-stretch: condensed;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_bold.otf') format('opentype');
    font-weight: 700;
    font-stretch: condensed;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_extra_bold.otf') format('opentype');
    font-weight: 800;
    font-stretch: condensed;
}

/* Futura condensed oblique */

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_oblique_light.otf') format('opentype');
    font-weight: 300;
    font-stretch: condensed;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_oblique_medium.otf') format('opentype');
    font-weight: 500;
    font-stretch: condensed;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_oblique_bold.otf') format('opentype');
    font-weight: 700;
    font-stretch: condensed;
    font-style: oblique;
}

@font-face {
    font-family: 'Futura LT';
    src: url('../../fonts/agconnect/futura_condensed_oblique_extra_bold.otf') format('opentype');
    font-weight: 800;
    font-stretch: condensed;
    font-style: oblique;
}

/*
*  Yrsa
*/

@font-face {
    font-family: 'Yrsa';
    src: url('../../fonts/agconnect/yrsa_light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Yrsa';
    src: url('../../fonts/agconnect/yrsa_regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Yrsa';
    src: url('../../fonts/agconnect/yrsa_medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Yrsa';
    src: url('../../fonts/agconnect/yrsa_semi_bold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Yrsa';
    src: url('../../fonts/agconnect/yrsa_bold.ttf') format('truetype');
    font-weight: 700;
}

/* Font families */

$ff-serif: 'Yrsa', serif;
$ff-sans-serif: 'Futura LT', sans-serif;
$ff-monospace: 'Roboto Mono', monospace;

/* Font sizes */
$fs: 1.5rem;
$fs--small: (
    xs: 1.1rem,
    sm: 1.2rem
);
$fs--medium: (
    xs: 1.5rem,
    sm:1.7rem
);
$fs--p: (
    xs: 1.7rem,
    sm: 2.1rem
);
$fs--h1: 4rem;
$fs--h2: 3.2rem;
$fs--h3: (
    xs: 1.7rem,
    sm: 1.9rem
);
$fs--label: 1.4rem;
$fs--tag: 1.1rem;
$fs--slogan: 1.9rem;
$fs--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$fs--article-header-lead: (
    xs: 2rem,
    sm: 2.5rem
);
$fs--article-header-meta: $fs--small;
$fs--teaser-label: $fs--small;
$fs--teaser-day: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--teaser-month: (
    xs: 0.9rem,
    sm: 1.2rem
);
$fs--teaser-title: (
    xs: 1.8rem,
    sm: 2.5rem
);
$fs--user-description: $fs--small;
$fs--caption: $fs--small;
$fs--quote-quote: (
    xs: 1.4rem,
    sm: 1.8rem
);
$fs--quote-author: $fs--small;
$fs--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$fs--comment-author: $fs--medium;
$fs--comment-date: $fs--small;
$fs--highlight: 1.9rem;
$fs--whitepaper-link-title: (
    xs: 1.8rem,
    sm: 1.9rem
);
$fs--whitepaper-link-number: (
    xs: 2rem,
    sm: 3rem
);
$fs--vacancy-link-title: (
    xs: $fs,
    sm: 1.6rem
);
$fs--dossier-link: (
    xs: 1.5rem,
    sm: 1.9rem
);
$fs--pagination: (
    xs: 1.4rem,
    sm:1.5rem
);
$fs--form: 1.6rem;
$fs--form-help: (
    xs: 1.4rem,
    sm: 1.5rem
);
$fs--cross-link-title: $fs--medium;
$fs--page-header-title: (
    xs: 2.1rem,
    sm: 3rem
);

/* Line heights */
$lh: 2rem;
$lh--small: (
    xs: 1.5rem,
    sm:2rem
);
$lh--medium: (
    xs: 2.5rem,
    sm: 3rem
);
$lh--label: 1.6rem;
$lh--form: 1.8rem;
$lh--p: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--h1: 4.2rem;
$lh--h2: 3.2rem;
$lh--h3: $lh--medium;
$lh--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
);
$lh--article-header-lead: (
    xs: 2.5rem,
    sm: 2.5rem
);
$lh--article-header-meta: $lh--small;
$lh--teaser-label: $lh--small;
$lh--teaser-title: (
    xs: 2rem,
    sm: 3rem
);
$lh--user-description: $lh--small;
$lh--caption: $lh--small;
$lh--quote-quote: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--quote-author: $lh--small;
$lh--first-letter: (
    xs: 5rem,
    sm: 7rem
);
$lh--comment-author: 3rem;
$lh--comment-date: $lh--small;
$lh--highlight: 2.5rem;
$lh--whitepaper-link-title: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--whitepaper-link-number: (
    xs: 2.5rem,
    sm: 3.5rem
);
$lh--vacancy-link-title: $lh--medium;
$lh--dossier-link: (
    xs: 2rem,
    sm: 2.5rem
);
$lh--form-help: (
    xs: 1.7rem,
    sm: 1.8rem
);
$lh--cross-link-title: $lh--medium;
$lh--page-header-title: (
    xs: 3rem,
    sm: 4rem
);

/* Letter spacings */
$ls--label: 1px;
