.c-toggle {
  white-space: nowrap;
  margin: 0;
  display: block;
}

.c-toggle__checkbox {
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
}

.c-toggle__checkbox:checked + .c-toggle__text {
  span {
    &:first-of-type {
      border-width: 1px;
      box-shadow: $box-shadow--invert;
      background: $color-white;
    }

    &:last-of-type {
      background: $color-grey--lighter;
      border-width: 1px 1px 1px 0;
      box-shadow: $box-shadow--inset;
    }
  }
}

.c-toggle__text {
  display: inline-flex;
  font-family: $ff-monospace;
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);

  span {
    border: solid #808080;
    display: inline-block;
    cursor: pointer;
    padding: .5rem 2rem;

    &:first-of-type {
      background: $color-grey--lighter;
      border-width: 1px 0 1px 1px;
      box-shadow: $box-shadow--inset;
    }

    &:last-of-type {
      border-width: 1px;
      box-shadow: $box-shadow;
      background: $color-white;
    }
  }
}
