/**
* @file Basic styling for tab groups & tabs, also the drop-down styling is included
*
* @example
* <ul class="c-tab-group">
*     <li class="c-tab-group__tab is-selected">tab 1</li>
*     <li class="c-tab-group__tab">tab 2</li>
*     <li class="c-tab-group__tab">tab 3</li>
*     <li class="c-tab-group__tab c-tab-group__tab--icon">
*       <i class="icon-social--facebook"></i>
*     </li>
*     <li class="c-tab-group__tab c-tab-group__tab--icon">
*       <i class="icon-social--linkedin"></i>
*     </li>
*     <li class="c-tab-group__tab c-tab-group__tab--icon">tab 3</li>
*     <li class="c-tab-group__tab c-tab-group__tab--drop-down js-drop-down">
*       tab with dropdown
*       <ul class="c-tab-group__drop-down">
*         <li class="c-tab-group__drop-down-item">sub tab 1</li>
*         <li class="c-tab-group__drop-down-item">sub tab 2</li>
*         <li class="c-tab-group__drop-down-item">sub tab 3</li>
*       </ul>
*     </li>
*   </ul>
 */
.c-tab-group {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: $color-black;
  font-family: $ff-sans-serif;
  font-size: $fs--label;
  line-height: $lh--label;
  letter-spacing: $ls--label;
  text-transform: uppercase;
}

.c-tab-group--secondary {
  text-transform: none;
  letter-spacing: initial;
  font-size: $fs;
  line-height: $lh;

  .c-tab-group__tab {
    padding-bottom: 4px;
  }

  .c-tab-group__tab:hover,
  .c-tab-group__tab.is-selected {
    &:after {
      height: 4px;
    }
  }
}

.c-tab-group__tab {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: inherit;

  &:after {
    content: '';
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color-yellow;
  }

  & + & {
    margin-left: 20px;
  }
  &--icon + &--icon {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  i[class^=icon-] {
    color: inherit;
  }
}

.c-tab-group__tab:hover,
.c-tab-group__tab.is-selected {
  &:after {
    height: 1px;
  }
}

.c-tab-group__tab.is-selected {
  font-weight: bold;
}

.c-tab-group__tab--drop-down {
  @include icon(arrow-alt--down);
  padding-right: 20px;

  &:before {
    transition: $default-transition;
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 12px;
  }
}

.c-tab-group__drop-down {
  position: absolute;
  top: 100%;
  right: -14px;
  left: auto;
  background: #fff;
  border: 1px solid #000;
  box-shadow: $box-shadow;
  transition: $default-transition;
  transform: translateY(-5px);
  opacity: 0;
  height: 0;
  overflow: hidden;

  &:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border: solid #000;
    border-width: 1px 1px 0 0;
    background: #fff;
    position: absolute;
    top: -4.5px;
    right: 15px;
    transform: rotate(-45deg);
  }
}

.c-tab-group__drop-down-item {
  padding: 14px 20px 12px;
  border-bottom: 1px solid $color-grey--light;

  &:last-of-type {
    border: none;
    padding-bottom: 13px;
  }
}

.c-tab-group__tab--drop-down.is-opened {
  &:before {
    transform: rotate(180deg);
  }

  .c-tab-group__drop-down {
    overflow: visible;
    transform: translateY(0);
    opacity: 1;
    z-index: 1;
    margin-top: 8px;
    height: auto;
  }
}

.c-tab-group--inverse {
  color: $color-white;

  .c-tab-group__tab:after {
    background: $color-white;
  }
}
