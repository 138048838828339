.c-postcode-check {
}

.c-postcode-check__button {
  display: none;
}

.c-postcode-check__loading {
  display: none;
}

.c-postcode-check__error {
  display: none;
  margin-bottom: 1rem;
  color: $color-red;
}
