input.parsley-success,
select.parsley-success,
textarea.parsley-success {
    color: $field-success-color;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    color: $field-error-color;
}

.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.parsley-errors-list.filled {
    color: $field-error-color;
    display: block;
}

.parsley-errors-list li {
    margin-top: $field-margin;
}
