.c-checkbox {
    @include checkboxAndRadioButtonBase;

    &__label {
        display: inline;

        .c-checkbox__input:checked:not(:disabled) + & {
            &::before {
                background-color: $c-main;
                background-image: url(../../../../images/agconnect-new/icons/checkmark-thick--white.svg);
            }
        }

        .c-checkbox__input:checked:disabled + & {
            &::before {
                background-image: url(../../../../images/agconnect-new/icons/checkmark-thick--grey.svg);
            }
        }
    }
}
