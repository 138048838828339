.c-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:after {
    @extend %icon;
    content: icon-char(arrow--right);
    font-size: 1rem;
    margin-left: .5rem;
  }
}

.c-link-reverse {
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:before {
    @extend %icon;
    content: icon-char(arrow--left);
    font-size: 1rem;
    margin-left: .5rem;
  }
}

.c-link__text {
  text-decoration: underline;
}
