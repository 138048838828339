.c-ticket {
    font-size: 1.6rem;
    @extend .l-col--5-md;
    @extend .l-col--12;

    @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 1.5rem;
    }
}

.c-ticket__body {
    background: $color-white;
    border: 0;
}

.c-ticket__details hr {
    border: 0;
    margin: 0;
    height: 2px;
    background-color: white;

    &:before {
        content: '';
        display: block;
        border: solid 1px lighten($c-main, 25);
        margin: 0 20px;
    }
}

.c-signup-price.c-pill {
    text-transform: lowercase;
}

.c-signup-price--price {
    font-weight: bold;
}

.c-signup-price-info {
    margin-top: 2rem;
    font-size: 9pt;
}

.c-ticket__subscription_include {
    position: relative;
    padding-bottom: 1rem;
    padding-left: 2rem;
    font-family: $ff-sans;
    font-size: 11pt;

    &:before {
        content: '';
        background-image: url('../../../images/agconnect-new/icons/checkmark--blue.svg');
        position: absolute;
        top: 0.2rem;
        left: 0;
        background-size: 1.6rem 1.6rem;
        height: 1.6rem;
        width: 1.6rem;
    }
}

.c-ticket__subscription_include__label {
    font-weight: bold;
}

.c-ticket__subscription_include__price {
    font-weight: bold;
    float: right;
}

.c-ticket__subscription_include__before-discount-price {
    float: right;
    color: $c-support;
    text-decoration: line-through;
    margin-right: .5rem;
}

.c-ticket__subscription_include__description {
    display: block;
}

.c-ticket__notices {
    margin-top: 1rem;
    background: lighten($c-main, 45);
}

.c-ticket__notice {
    text-align: center;
    font-size: 1.2rem;
    padding: 0.3rem;
}

.c-ticket__notice + .c-ticket__notice {
    padding-top: 0;
}

.c-ticket__summary_subscription__price {
    color: $color-grey;
}

.c-signup-price {
    display: inline;
    float: right;
}

.c-ticket__ticket {

}
