/* Font families */

$ff-sans-serif: sans-serif !default;
$ff-serif: sans-serif !default;
$ff-monospace: monospace !default;

/* Font sizes */
$fs: 1.5rem !default;
$fs--small: (
    xs: 1.1rem,
    sm: 1.2rem
) !default;
$fs--medium: (
    xs: 1.5rem,
    sm:1.7rem
) !default;
$fs--p: (
    xs: 1.7rem,
    sm: 2.1rem
) !default;
$fs--h1: 3.5rem !default;
$fs--h2: 2.5rem !default;
$fs--h3: (
    xs: 1.7rem,
    sm: 1.9rem
) !default;
$fs--label: 1.2rem !default;
$fs--tag: 1.1rem !default;
$fs--slogan: 1.9rem !default;
$fs--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
) !default;
$fs--article-header-lead: (
    xs: 2rem,
    sm: 2.5rem
) !default;
$fs--article-header-meta: $fs--small !default;
$fs--teaser-label: $fs--small !default;
$fs--teaser-day: (
    xs: 1.5rem,
    sm: 1.9rem
) !default;
$fs--teaser-month: (
    xs: 0.9rem,
    sm: 1.2rem
) !default;
$fs--teaser-title: (
    xs: 1.8rem,
    sm: 2.5rem
) !default;
$fs--user-description: $fs--small !default;
$fs--caption: $fs--small !default;
$fs--quote-quote: (
    xs: 1.4rem,
    sm: 1.8rem
) !default;
$fs--quote-author: $fs--small !default;
$fs--first-letter: (
    xs: 5rem,
    sm: 7rem
) !default;
$fs--comment-author: $fs--medium !default;
$fs--comment-date: $fs--small !default;
$fs--highlight: 1.9rem !default;
$fs--whitepaper-link-title: (
    xs: 1.8rem,
    sm: 1.9rem
) !default;
$fs--whitepaper-link-number: (
    xs: 2rem,
    sm: 3rem
) !default;
$fs--vacancy-link-title: (
    xs: $fs,
    sm: 1.6rem
) !default;
$fs--dossier-link: (
    xs: 1.5rem,
    sm: 1.9rem
) !default;
$fs--pagination: (
    xs: 1.4rem,
    sm:1.5rem
) !default;
$fs--form-help: (
    xs: 1.4rem,
    sm: 1.5rem
) !default;
$fs--cross-link-title: $fs--medium !default;
$fs--page-header-title: (
    xs: 2.1rem,
    sm: 3rem
) !default;

/* Line heights */
$lh: 2rem !default;
$lh--small: (
    xs: 1.5rem,
    sm:2rem
) !default;
$lh--medium: (
    xs: 2.5rem,
    sm: 3rem
) !default;
$lh--label: 1.5rem !default;
$lh--form: 1.8rem !default;
$lh--p: (
    xs: 2.5rem,
    sm: 3.5rem
) !default;
$lh--h1: 4.2rem !default;
$lh--h2: 3.2rem !default;
$lh--h3: $lh--medium !default;
$lh--article-header-title: (
    xs: 2.9rem,
    sm: 5rem
) !default;
$lh--article-header-lead: (
    xs: 2.5rem,
    sm: 2.5rem
) !default;
$lh--article-header-meta: $lh--small !default;
$lh--teaser-label: $lh--small !default;
$lh--teaser-title: (
    xs: 2rem,
    sm: 3rem
) !default;
$lh--user-description: $lh--small !default;
$lh--caption: $lh--small !default;
$lh--quote-quote: (
    xs: 2.5rem,
    sm: 3.5rem
) !default;
$lh--quote-author: $lh--small !default;
$lh--first-letter: (
    xs: 5rem,
    sm: 7rem
) !default;
$lh--comment-author: 3rem !default;
$lh--comment-date: $lh--small !default;
$lh--highlight: 2.5rem !default;
$lh--whitepaper-link-title: (
    xs: 2rem,
    sm: 2.5rem
) !default;
$lh--whitepaper-link-number: (
    xs: 2.5rem,
    sm: 3.5rem
) !default;
$lh--vacancy-link-title: $lh--medium !default;
$lh--dossier-link: (
    xs: 2rem,
    sm: 2.5rem
) !default;
$lh--form-help: (
    xs: 1.7rem,
    sm: 1.8rem
) !default;
$lh--cross-link-title: $lh--medium !default;
$lh--page-header-title: (
    xs: 3rem,
    sm: 4rem
) !default;

/* Letter spacings */
$ls--label: 1px !default;
