.u-membership-builder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.u-membership-builder .t-heading {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: normal;
    margin-top: 2rem;
  }
}

.c-membership-builder--option {
  cursor: pointer;
  width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
  border: 0;
}

.c-membership-builder--option--selected {
  .c-membership-builder--option--label, .c-membership-builder--option--description, .c-membership-builder--option--price, .c-membership-builder--option--before-discount-price {
    color: white;
  }
}

.c-membership-builder--option--label {
  font-weight: bold;
  text-transform: uppercase;
}

.c-membership-builder--option--description {
  margin: 5px 0;
  font-size: 9pt;
  color: $c-text;
  font-weight: normal;
  line-height: 11pt;
}

.c-membership-builder--option--price {
  color: $c-text;
  font-weight: bold;
  float: right;
}

//currently not in use but preserved if we want to add price discounts
.c-membership-builder--option--before-discount-price {
  color: $c-support;
  text-decoration: line-through;
  float: right;
  margin-right: .5rem;
}

.c-membership-builder--option.c-button::before {
  border: 0;
}

body {
  background-color: #f2f0ee;
}
