.c-value-proposition {
    font-size: 1rem;
    padding: 3rem 2.5rem 5rem;

    @media screen and (min-width: 500px) {
        font-size: 1.25rem;
        padding: 3rem 5rem 5rem;
    }
    
    @include media-breakpoint-up(sm) {
        font-size: 1.45rem;
        padding: 7.5rem 1rem 10rem;
    }

    h1 {
        position: relative;
        display: inline;
        font-size: 4em;
        line-height: normal;
        font-weight: 600;
    }

    h1::after {
        content: '';
        position: absolute;
        height: 0.14em;
        width: 0.14em;
        right: -0.2em;
        bottom: 0.36em;
        background-color: $color-orange;
    }

    h2 {
        margin-top: 0.25rem;
        font-size: 2.5em;
        line-height: normal;
        font-weight: 600;
        color: $color-grey--dark;
    }
}
