/**
* @file
* These mixins will output the grid
*/

/**
* Container widths
* Set the container width, and override it for fixed navbars in media queries.
*/
@if $enable-grid-classes {
  .l-container {
    @include make-container-custom();
    @include make-container-max-widths();

    &--small {
      max-width: 540px;
    }

    &--narrow {
      max-width: 730px;
    }
  }
}

/**
* Fluid container
* Utilizes the mixin meant for fixed width containers, but with 100% width for
* fluid, full width layouts.
 */
@if $enable-grid-classes {
  .l-container--fluid {
    @include make-container();
  }
}

/**
* Mobile fluid container
* Will make a container without padding for mobile devices
 */
.l-container--mobile-fluid {
  @include media-breakpoint-only(xs) {
    padding: 0;
  }
}

/**
* Row
*
* Rows contain and clear the floats of your columns.
*/
@if $enable-grid-classes {
  .l-row {
    @include make-row-custom();
  }

  /**
  * Remove the negative margin from default .row, then the horizontal padding
  * from all immediate children columns (to prevent runaway style inheritance).
   */
  .l-row--no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .l-col,
    > [class*="l-col"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .l-row--no-gutters-xs {
    @include media-breakpoint-only(xs) {
      margin-right: 0;
      margin-left: 0;

      > .l-col,
      > [class*="l-col"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

/**
* Columns
*
* Common styles for small and large grid columns
*/
@if $enable-grid-classes {
  @include make-grid-columns-custom();
}
