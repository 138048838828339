/**
* @file
* Overriding grid variables from smg-assets
*/

/**
Grid containers
* Define the maximum width of `.container` for different screen sizes.
* These maximum widths should include the padding on both sides, so the gutter-width * 2
 */
$container-max-widths: (
    sm: 720px,
    md: 960px,
    lg: 1140px
);
