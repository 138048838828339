.c-page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-page__header {

}
.c-page__body {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex: auto;
  background: $c-page-background;
  padding-top: 2rem;

  @include media-breakpoint-up(sm) {
    padding-top: 4rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: 6rem;
  }
}
