$dither-offset: 4px;
$hover-offset: 6px;
$active-offset: 0;

.c-button {
  $self: &;
  @include button-text;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  height: 4rem;
  padding: 0.6rem 1.6rem;
  transform: translate(-$dither-offset, -$dither-offset);
  border: none;
  cursor: pointer;
  color: var(--text-color, #{$c-white});
  background-color: transparent;
  transition: $default-transition;
  text-decoration: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: $default-transition;
  }

  &::before {
    // This is the background of the button
    z-index: -1;
    background: var(--bg-color, $c-main);
    border: 2px solid var(--border-color, transparent);
  }

  &::after {
    // This is the dithered underlayer
    z-index: -2;
    transform: translate($dither-offset, $dither-offset);
    background-image: repeating-conic-gradient(var(--dither-color, #{$c-text}) 0% 25%, $c-white 0% 50%);
    background-size: 4px 4px;
    background-position: bottom right;
  }

  &:focus,
  &:hover {
    transform: translate(-$hover-offset, -$hover-offset);

    &:after {
      transform: translate($hover-offset, $hover-offset);
    }
  }

  &:active {
    transform: translate(-$active-offset, -$active-offset);

    &:after {
      transform: translate($active-offset, $active-offset);
    }
  }

  &__text {
    position: relative;

    &::before,
    &::after {
      position: relative;
      vertical-align: sub;
    }

    &:not(.c-button__text--icon-only) {
      &::before {
        margin-right: 0.8rem;
      }

      &::after {
        margin-left: 0.8rem;
      }
    }
  }

  // Types
  $variantColors: (
          'primary': 'white',
          'secondary': 'blue',
          'tertiary': 'blue',
          'cta-primary': 'white',
          'cta-secondary': 'orange',
          'partner': 'green',
  );
  $typeIconsLeft: (
          'confirm': 'checkmark',
          'go-back': 'arrow--left',
  );
  $typeIconsRight: (
          'go': 'arrow--right',
          'follow': 'plus',
          'dropdown': 'chevron--down',
          'download': 'download',
  );

  @each $variant, $color in $variantColors {
    @each $type, $icon in $typeIconsLeft {
      &--#{ $variant }#{ $self }--#{ $type } {
        #{ $self }__text::before {
          content: url('../../../images/agconnect-new/icons/buttons/#{ $icon }--#{ $color }.svg');
        }
      }
    }
    @each $type, $icon in $typeIconsRight {
      &--#{ $variant }#{ $self }--#{ $type } {
        #{ $self }__text::after {
          content: url('../../../images/agconnect-new/icons/buttons/#{ $icon }--#{ $color }.svg');
        }
      }
    }
  }

  // Variants
  &--primary {
    --text-color: #{$c-white};
    --dither-color: #{$c-main};
    --bg-color: #{$c-main};
  }

  &--secondary {
    --text-color: #{$c-main};
    --dither-color: #{$c-main};
    --bg-color: #{$c-white};
    --border-color: #{$c-main};
  }

  &--tertiary {
    --text-color: #{$c-main};
    padding-left: 0;
    padding-right: 0;
    transform: none;

    #{ $self }__text {
      text-decoration: underline;
    }

    &::before,
    &::after {
      content: none;
    }

    &:focus,
    &:hover,
    &:active {
      transform: none;
    }
  }

  &--cta-primary {
    --text-color: #{$c-white};
    --dither-color: #{$c-cta};
    --bg-color: #{$c-cta};
  }

  &--cta-secondary {
    --text-color: #{$c-cta};
    --dither-color: #{$c-cta};
    --bg-color: #{$c-white};
    --border-color: #{$c-cta};
  }

  &--partner {
    --text-color: #{$c-partner};
    padding-left: 0;
    padding-right: 0;
    transform: none;

    #{ $self }__text {
      text-decoration: underline;
    }

    &::before,
    &::after {
      content: none;
    }

    &:focus,
    &:hover,
    &:active {
      transform: none;
    }
  }

  &[disabled] {
    --text-color: #{$c-white};
    --dither-color: #{$c-disabled};
    --bg-color: #{$c-disabled};
    cursor: auto;

    &:focus,
    &:hover,
    &:active {
      transform: translate(-$dither-offset, -$dither-offset);

      &:after {
        transform: translate($dither-offset, $dither-offset);
      }
    }
  }
}

.button {
  position: relative;
  display: block;
  background: $c-primary-button-background;
  color: $c-primary-button;
  text-decoration: none;
  padding: 1rem 2rem;
  margin-right: 1rem;
  border: 0;
  font-size: 1.6rem;
  cursor: pointer;

  &.center {
    margin: 0 auto;
  }

  &--loading {
    color: $c-primary-button-background;
    pointer-events: none;

    &::after {
      content: url('../../../images/agconnect-new/circular-spinner.svg');
      position: absolute;
      top: calc(50% + 2px);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
