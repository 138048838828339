.c-page-title {
  font-size: map_get($fs--article-header-lead, xs);
  line-height: map_get($lh--article-header-lead, xs);
  font-family: $ff-sans-serif;
  font-weight: 300;
  margin-bottom: 2rem;
  
  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--article-header-lead, sm);
    line-height: map_get($lh--article-header-lead, sm);
  }
}
