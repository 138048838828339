.c-select {
    @include rectangularInputBase;
    position: relative;

    &__input {
        $background-size: 1.6rem;
        background-image: url('../../../../images/agconnect-new/icons/chevron--down.svg');
        background-color: $c-white;
        background-repeat: no-repeat;
        background-size: $background-size $background-size;
        background-position: calc(100% - spacer(3)) center;
        padding-right: calc(spacer(2) + $background-size + spacer(3));

        // Hide default chevron
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
    }
}
