.c-page-header {
  text-align: center;
  padding: 1.5rem 0 2rem;
  border-bottom: 1px solid $color-grey--light;

  @include media-breakpoint-up(sm) {
    padding: 1rem 0 3rem;
  }
}

.c-page-header__img-container {
  margin: 0 auto .5rem;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-page-header__img {
  max-width: 8rem;
  max-height: 8rem;
}

.c-page-header__label,
.c-page-header__text,
.c-page-header__info {
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--small, sm);
    line-height: map_get($lh--small, sm);
  }
}

.c-page-header__label,
.c-page-header__info {
  text-transform: uppercase;
}

.c-page-header__title {
  font-family: $ff-sans-serif;
  font-weight: 400;
  font-size: map_get($fs--page-header-title, xs);
  line-height: map_get($lh--page-header-title, xs);
  margin-bottom: .5rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 1.5rem;
    font-size: map_get($fs--page-header-title, sm);
    line-height: map_get($lh--page-header-title, sm);
  }
}

.c-page-header__info,
.c-page-header__text {
  color: $color-grey--light;
}

.c-page-header__info {
  display: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-bottom: 2rem;
  }
}

.c-page-header__text {
  color: $color-grey--light;
  font-family: $ff-monospace;
  line-height: map_get($lh--small, sm);
}

.c-page-header__social {
  display: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-top: 2rem;
  }
}
