/* Use in combination with JavaScript that hides all elements wit this class on page load */
.u-hide-if-js {
    /* stylelint-disable-line block-no-empty */
}

/* Use in combination with JavaScript that removes this class on page load */
.u-show-if-js {
    display: none !important;
}

/* Use when element should be hidden until a certain event which can be any event but page load */
.u-hidden {
    display: none !important;
}
