.c-request-form {
  padding: 2rem 1.5rem 3rem;
  background: $color-grey--lightest;
  margin-bottom: 6rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 8rem;
    padding: 4rem 1.5rem 5rem;
  }
}

.c-request-form__body {
  max-width: 46rem;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    max-width: 54rem;
  }
}

.c-request-form__submit {
  width: 100%;
  margin-top: .5rem;

  @include media-breakpoint-up(sm) {
    width: 23rem;
    margin-top: 0;
  }
}
