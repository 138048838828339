/**
 * @file
 * Styling for the footer component
 *
 * @example
 * <footer class="c-site-footer">
 *   <div  class="l-container c-site-footer__container">
 *     <div class="c-site-footer__body">
 *       <div class="c-site-footer__branding">
 *       <div class="c-footer-branding"></div>
 *     </div>
 *   </div>
 * </footer>
 */

.c-page__footer {
  margin-top: auto;
}

.c-site-footer {
  background: $color-black;

  &.c-site-footer--dpg {
    background: $color-white;

    .c-site-footer__body p {
      font-size: 9pt;
      line-height: 48px;
    }
  }

  .c-footer-branding__logo {
    flex-direction: row;

    img {
      vertical-align: middle;
      max-height: 48px;
      max-width: 20rem;
    }
  }

  .c-footer-branding {
    max-height: 64px;
  }
}

.c-site-footer__container {
  @include media-breakpoint-up(lg) {
    max-width: 1380px;
  }
}

.c-site-footer__body {
  position: relative;
  display: flex;
}

.c-site-footer__branding {
  margin-left: auto;
}
