/**
* @file
* Styling for the header component
*
* @example
* <header class="c-site-header">
*   <div class="c-site-header__body">
*     <div class="c-site-header__branding">...</div>
*   </div>
* </header>
*
 */

.c-site-header {
    background: $color-white;
    height: 11.6rem;
}

.c-site-header__container {
    height: 100%;

    @include media-breakpoint-up(lg) {
        max-width: 1380px;
    }
}

.c-site-header__body {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    @include media-breakpoint-up(sm) {
        padding: 1.5rem 0;
    }
}

.c-site-header__back {
    font-size: 2rem;
    text-decoration: none;
    text-transform: capitalize;
    transition: $default-transition;

    @include media-breakpoint-up(sm) {
        text-transform: none;
    }
}

.c-site-header__logo img {
    height: 5rem;
}

.c-site-header__login {
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    transition: $default-transition;

    span {
        font-size: 2rem;
        margin-left: 1.2rem;
    }

    svg {
        stroke: $c-main;
        stroke-width: 2;
        transition: $default-transition;
    }

    &:hover svg {
        stroke: $color-blue--dark;
    }
}

.c-site-header__help {
    right: 0;
}
