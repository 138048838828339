.c-ticket-parent {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .c-ticket {
    @extend .l-col--4-md;
    @extend .l-col--12;
    display: none;
    position: sticky;
    z-index: 1;
    bottom: 0;
    padding: 0;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    margin-top: auto;
    min-width: 100vw;

    @include media-breakpoint-up(sm) {
      margin-right: calc((100vw - 700px)/-2);
      margin-left: calc((100vw - 700px)/-2);
    }

    @include media-breakpoint-up(md) {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      min-width: calc(100%/3);
      padding-right: 1.5rem;
    }

    &.c-ticket--visible {
        display: block;
      .c-ticket__summary {
        @include media-breakpoint-down(sm) {
          display: flex;
        }
      }
    }
  }

  .c-ticket__ticket {
    position: sticky;
    top: 100px;

    .c-signup-title {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      z-index: 10;
      position: relative;
      bottom: 0;
      left: 0;
      top: auto;
      width: 100%;
    }
  }

  .c-ticket--expanded {
    .c-ticket__details {
      display: block;
    }
    .c-ticket__summary_choice:before {
      content: icon-char(arrow-alt--down);
    }
    .c-ticket__summary_subscription {
      display: none;
    }
    .c-ticket__overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  .c-ticket__overlay {
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 9999999px;
    display: none;
  }

  .c-ticket__summary {
    background: $color-white;
    border-top: 1px solid $color-grey--lighter;
    display: none;
    padding: 1rem 1.5rem;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      padding: 1rem calc((100vw - 700px)/2);
    }
  }

  .c-ticket__summary_choice {
    flex: 1;
    padding-left: 2rem;
    position: relative;
    align-self: center;

    &:before {
      @extend %icon;
      content: icon-char(arrow-alt--up);
      color: $color-black;
      position: absolute;
      top: 0.3rem;
      left: 0;
      font-size: 1.3rem;
    }
  }

  .c-ticket__summary_subscription {
    flex: 1;
  }

  .c-ticket__summary_subscription__subscription,
  .c-ticket__summary_subscription__price {
    flex: 1;
    text-align: right;
  }

  .c-ticket__summary_subscription__price {
    color: $color-grey--lighter;
  }

  .c-ticket__details {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .c-ticket__body {
    border: 1px solid $color-grey--lighter;
    padding: 3rem 3rem 2rem 3rem;

    @include media-breakpoint-down(sm) {
      border: 0;
    }

    @include media-breakpoint-up(sm){
      padding: 1rem calc((100vw - 700px)/2);
    }

    @include media-breakpoint-up(md) {
      padding: 3rem 3rem 2rem 3rem;
    }
  }

  .c-ticket__body + .c-ticket__body {
    border-top: 0;
  }

  .c-ticket__subscription {
    border-bottom: 1px solid $color-grey--lighter;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .c-ticket__pricing {}

  .c-ticket__subscription_row,
  .c-ticket__pricing_row {
    display: flex;
    flex-direction: row;
  }

  .c-ticket__subscription_row span,
  .c-ticket__pricing span {
    flex: 1;
    padding-bottom: 1rem;
  }

  .c-ticket__subscription_label,
  .c-ticket__pricing_label {
    font-weight: bold;
  }

  .c-ticket__subscription_value,
  .c-ticket__pricing_value,
  .c-ticket__pricing_value__secondary {
    text-align: right;
  }

  .c-ticket__pricing_value {
    font-size: 2.7rem;
    font-weight: bold;
  }

  .c-ticket__pricing_value__secondary {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .c-ticket__pricing_row--dimmed {
    color: $color-grey--light;

    .c-ticket__pricing_value {
      font-size: 1.5rem;
    }
  }

  .c-ticket__options {

  }

  .c-ticket__options_header {
    font-weight: bold;
    padding-bottom: 0.8rem;
  }

  .c-ticket__subscription_includes {

  }
