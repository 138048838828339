.c-pricing-block {
  margin-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 4rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 6rem;
  }
}

.c-pricing-block__content {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
}

.c-pricing-block__item {
  @include media-breakpoint-up(sm) {
    width: calc(33.33% - .5rem);
  }

  @include media-breakpoint-up(md) {
    width: calc(33.33% - .75rem);
  }
}
