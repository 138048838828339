/**
* @file
* The base styling for the typography
 */
html {
  /* Reset font size so we can work with rem units */
  font-size: 62.5%;
}

body {
  font: normal normal #{$fs} / #{$lh} $ff-sans;
  -webkit-font-smoothing: antialiased;
}

h1, h2, p {
  font-family: $ff-sans;
}

h1, h2 {
  font-weight: bold;
}

h1 {
  font-size: $fs--h1;
  line-height: $lh--h1;
}

h2 {
  font-size: $fs--h2;
  line-height: $lh--h2;
}

h3 {
  font-size: map_get($fs--h3, xs);
  line-height: map_get($lh--h3, xs);

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--h3, sm);
    line-height: map_get($lh--h3, sm);
  }
}

p {
  font-size: map_get($fs--p, xs);
  line-height: map_get($lh--p, xs);
  color: $color-grey--dark;

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--p, sm);
    line-height: map_get($lh--p, sm);
  }
}

a {
  color: $c-link;

  &:hover {
    color: $color-blue--dark;
  }
}

a[disabled],
a.is-disabled {
  pointer-events: none;
  color: rgba($color-blue, .3);
}

.icon-social--facebook {
  color: $color-social--facebook;
}
.icon-social--twitter {
  color: $color-social--twitter;
}
.icon-social--linkedin {
  color: $color-social--linkedin;
}
[class^="icon-"]:before {
  vertical-align: text-top;
}
