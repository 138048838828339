.c-radio-button {
    @include checkboxAndRadioButtonBase;

    &__label {
        &::before,
        &::after {
            border-radius: 50px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0.3rem;
            width: 1.4rem;
            height: 1.4rem;
        }

        .c-radio-button__input:checked:not(:disabled) + & {
            &::after {
                background-color: $c-main;
            }
        }

        .c-radio-button__input:checked:disabled + & {
            &::after {
                background-color: $c-support;
            }
        }
    }
}
