/**
* @file
* Basic styling for select boxes
*
* @example
* <div class="c-date-select">
*   <select name="select" id="select" class="c-select">
*     <option value="">Selecteer een maand</option>
*     <option value="1">1</option>
*     <option value="2">2</option>
*     <option value="3">3</option>
*   </select>
* </div>
 */

.c-date-select .c-select {
  width: auto;
  padding-right: 3rem;
  margin-right: 1rem;
}
