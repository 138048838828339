.c-form-row {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
}

.c-form-row--more-space {
  @include media-breakpoint-up(sm) {
    margin-bottom: 3rem;
  }
}

.c-form-row--inline-sm {
  @include media-breakpoint-up(sm) {
    width: 25.5rem;

    & + .c-form-row--inline-sm {
      margin-left: 2rem;
    }
  }
}

.c-form-row--horizontal > .c-radio-button {
  @include media-breakpoint-up(xs) {
    display: inline-block;
    margin-right: 2rem;
  }
}
