/**
* @file
* This file should contain variables which are commonly used,
* But don't necessarily need their own file
*/

/* Default Shadows */
$box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1) !default;
$box-shadow--inset: inset #{$box-shadow} !default;
$box-shadow--invert: -3px 3px 0 0 rgba(0, 0, 0, 0.1) !default;

/* Default transitions */
$default-tween: cubic-bezier(0.5, 0, 0, 1) !default;
$default-transition: all 0.5s $default-tween !default;

/* Default input width */
$form-input-width: 255px !default;

/* Default button padding */
$form-button-padding: 0.8rem 1.6rem !default;

/* Parsley.js */
$field-margin: 1rem !default;
