.c-info-page__header {
  margin-bottom: 2rem;
  font-size: 3rem;
}

.c-info-page__body {
  background: $color-white;
  padding: 2.5rem 1.5rem;
  border: 1px solid $color-grey--lighter;
}
