html {
    overflow-y: scroll;
}

body {
    counter-reset: signup-steps order-steps;
}

.l-form-error-banner {
    position: sticky;
    top: 69px;
    z-index: 1;
}

.c-postcode-check__loading {
    display: none;
    margin-bottom: 1rem;
    color: $color-green;
}

.c-postcode-check__error {
    display: none;
    margin-bottom: 1rem;
    color: $color-red;
}

.c-postcode-check__timeout-error {
    display: none;
    margin-bottom: 1rem;
    color: $color-red;
}

.c-postcode-check__takes-long {
    display: none;
    margin-bottom: 1rem;
    color: $color-green;
}

.c-postcode-check__incorrect-address {
    display: none;
    text-align: right;
}

.c-form-spacer {
    height: 7rem;
}

.u-center {
    text-align: center;
}

.u-pull-right {
    float: right;
}

hr {
  margin: 3rem 0;
  border: 0;
  border-top: 1px solid $color-grey;
  &.registration {
    position: relative;
  }
}

hr.registration::after {
  content: 'of';
  color: $color-grey;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 0 3rem;
  top: -1.1rem;
}

// Honeypot for newsletter subscribe forms
.c-form-row:has(#subscribe_email_address_to_newsletters_form_name) {
    position: absolute;
    left: -9999px;;

    label {
        font-size: 0;

        &::before {
            content: 'Dit veld niet invullen';
            font-size: 14px;
        }
    }
}

.u-check-success {
  color: #080;
}
