/**
* @file
* The main css file,
* it should only import partials and
* never include any code
*/

/**
* Vendor styling from third party libraries
*/

/* Font Awesome */
@import '~font-awesome/scss/font-awesome';

/* Bootstrap */
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import 'vendor/bootstrap-reboot';

/**
* Base styling
* These files should contain variables and mixins but no actual class names
*/
@import 'base/grid';
@import 'base/colors';
@import 'base/common';
@import 'base/font';

/**
* Mixins
*/
@import 'mixins/index';

/**
* Common styling
*/
@import 'common/parsley';
@import 'common/utilities';
