/*
*  IBM Plex Sans
*/
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSans-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

/*
*  IBM Plex Serif
*/
@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexSerif-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

/*
*  IBM Plex Mono
*/
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-ExtraLight.ttf');
    font-weight: 200;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../fonts/agconnect-new/ibm-plex/IBMPlexMono-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

$ff-sans: 'IBM Plex Sans', sans-serif;
$ff-serif: 'IBM Plex Serif', serif;
$ff-mono: 'IBM Plex Mono', monospace;
