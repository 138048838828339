.c-standard-features {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    
    @media screen and (min-width: 500px) {
        font-size: 2.2rem;
    }
    
    @include media-breakpoint-up(sm) {
        font-size: 2.8rem;
    }
}

.c-standard-features__balloon {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    width: 5.9em;
    height: 5.9em;
    background-image: url('../../../images/agconnect/ag-balloon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 3rem 1.5rem;
    text-align: center;
    
    span {
        display: inline-block;
        font-size: 1em;
        line-height: normal;
        color: $color-blue;
        font-weight: 700;
    }
}

.c-standard-features__list {
    margin-left: 0.6em;
    font-family: $ff-serif;
    font-weight: 500;
    font-size: 1em;
    line-height: normal;
    color: $color-white;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);

    li {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0rem;
        }
    }
}
