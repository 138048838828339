.c-step {
  color: $color-black;
  text-decoration: none;
  display: flex;
  font-size: 1.2rem;
  line-height: 1;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }

  &:hover {
    color: $color-black;
  }

  &.is-uncompleted {
    color: $color-grey;
    pointer-events: none;

    .c-step__icon {
      border-color: $color-grey;
    }
  }

  &.is-completed {
    color: $c-main;

    .c-step__icon {
      border-color: $c-main;

      &:after {
        content: '';
        background-image: url('../../../images/agconnect-new/icons/checkmark--blue.svg');
        position: absolute;
        top: 4px;
        left: 5px;
        background-size: 14px;
        height: 14px;
        width: 14px;

        @include media-breakpoint-up(sm) {
          top: 6px;
          left: 7px;
          background-size: 16px;
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &.is-active {
    .c-step__text {
      display: inline-block;
    }
  }
}

.c-step__icon {
  position: relative;
  display: inline-flex;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 1px solid $color-black;
  align-items: center;
  justify-content: center;

  &:after {
    counter-increment: step-indicator;
    content: counter(step-indicator);
  }

  @include media-breakpoint-up(sm) {
    width: 3rem;
    height: 3rem;
  }
}

.c-step__text {
  display: none;
  padding-left: 1rem;
  align-self: center;

  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}
