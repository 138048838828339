.c-subscription-overview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
}

.c-subscription-overview__group-toggle {
    margin: 4rem 0;
}

.c-subscription-overview__footnote {
    position: absolute;
    bottom: 6px;
    font-family: $ff-sans-serif;
    font-size: 1.6rem;
    color: $color-grey--dark;
    text-align: center;
    line-height: 2.6rem;

    a {
        color: inherit;
        transition: color 200ms;

        &:hover {
            color: $color-black;
        }
    }
}

.c-subscription-overview__link {
    margin: 4rem 0;
    color: $color-grey--dark;
    font-family: $ff-sans-serif;
    font-size: 1.6rem;
    transition: color 200ms;

    &:hover {
        color: $color-black;
    }
}
