/**
* @file Basic form validation
*
* @example
* The <div> should wrap around an input field
* <div class="c-form-validation"><input class="c-form-control" type="text" /></div>
 */
.c-form-validation {
  position: relative;

  &:after {
    @extend %icon;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &.is-valid {
    &:after {
      content: icon-char(checkmark);
      color: $color-green;
    }

    input {
      border-color: $color-green;
    }

    .c-form-validation__message {
      color: $color-green;
    }
  }

  &.is-invalid {
    &:after {
      content: icon-char(close);
      color: $color-red;
    }

    input, select {
      box-shadow: $box-shadow--error;
    }

    .c-form-validation__message {
      color: $color-red;
    }
  }
}

.c-form-validation__message {
  margin-top: .5rem;
  display: block;
  font-family: $ff-monospace;
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--small, sm);
    line-height: map_get($lh--small, sm);
  }
}
