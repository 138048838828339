.c-form-footer {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.c-form-footer__description {
    flex: 5 0 0;
    font-size: 1.6rem;
    line-height: normal;
    margin-bottom: 2rem;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
}

.c-form-footer__action {
    text-align: right;
    flex: 4 0 0;
}
