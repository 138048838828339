.c-price {
  font-weight: 300;
  display: inline-flex;
  align-items: center;
}

.c-price__prefix,
.c-price__suffix {
  font-size: 1.4rem;
  line-height: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
}

.c-price__prefix {
  color: $color-grey--light;
}

.c-price__text {
  font-size: 2.5rem;
  line-height: 2.5rem;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
    line-height: 5rem;
  }
}
