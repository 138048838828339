.c-signup-title {
    display: inline;
    margin-top: 6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.5rem;
}

.c-signup-title--counter {
    font-size: 3rem;
    margin: 1rem 0 4rem;
    display: block;
}

.c-signup-title__change-button {
    margin-left: auto;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-decoration: underline;
    cursor: pointer;
  }
