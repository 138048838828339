.c-pricing-label {
  position: relative;
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    background: $color-grey--lighter;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--small, sm);
    line-height: map_get($lh--small, sm);
  }
}

.c-pricing-label__text {
  position: relative;
  z-index: 1;
  background: $color-white;
  padding: 0 1rem;

  @include media-breakpoint-up(sm) {
    padding: 0 1.5rem;
  }
}
