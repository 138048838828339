/**
* @file
* The main css file,
* it should only import partials and
* never include any code
*/

/* Styling from @sijthoffmedia/smg-assets */
@import '@sijthoffmedia/smg-assets/scss/agconnect/_style.scss';

/* Universal styling */
@import "common/universal";

@import "agconnect-new/variables/index";
@import "agconnect-new/mixins/index";
@import "agconnect-new/functions";

/*
* Basics styling
* These files should contain the styling for
* general layout, input elements, buttons, etc.
*/
@import "agconnect-new/basics/grid";
@import "agconnect-new/basics/typography";
@import "agconnect-new/basics/iconfont";
@import "agconnect-new/basics/button";
@import "agconnect-new/basics/block";
@import "agconnect-new/basics/form-label";
@import "agconnect-new/basics/form-control";
@import "agconnect-new/basics/select";
@import "agconnect-new/basics/tab-group";
@import "agconnect-new/basics/display";
@import "agconnect-new/basics/link";
@import "agconnect-new/basics/form-buttons";
@import "agconnect-new/basics/form-row";
@import "agconnect-new/basics/page";
@import "agconnect-new/basics/form-validation";
@import "agconnect-new/basics/page-title";
@import "agconnect-new/basics/toggle";
@import "adformatie/basics/ie11";

/*
* Components styling
* The files should contain the styling for
* components like header, footer, article, etc.
*/
@import "common/site-footer";
@import "common/ticket";

@import "agconnect-new/components/date-select";
@import "agconnect-new/components/date-select";
@import "agconnect-new/components/site-header";
@import "agconnect-new/components/site-branding";
@import "agconnect-new/components/page-header";
@import "agconnect-new/components/register-footnote";
@import "agconnect-new/components/register-title";
@import "agconnect-new/components/request-form";
@import "agconnect-new/components/form";
@import "agconnect-new/components/form-footer";
@import "agconnect-new/components/step-indicator";
@import "agconnect-new/components/step";
@import "agconnect-new/components/pricing-block";
@import "agconnect-new/components/pricing-option";
@import "agconnect-new/components/pricing-label";
@import "agconnect-new/components/price";
@import "agconnect-new/components/dashboard";
@import "agconnect-new/components/account-link";
@import "agconnect-new/components/postcode-check";
@import "agconnect-new/components/paragraph";
@import "agconnect-new/components/banner";
@import "agconnect-new/components/flash-messages";
@import "agconnect-new/components/info-page";
@import "agconnect-new/components/subscription-info";
@import "agconnect-new/components/signup-title";
@import "agconnect-new/components/ticket";
@import "agconnect-new/components/password-reset";
@import "agconnect-new/components/external-services";
@import "agconnect-new/components/ag-background";
@import "agconnect-new/components/value-proposition";
@import "agconnect-new/components/toggle-slider";
@import "agconnect-new/components/subscription-overview";
@import "agconnect-new/components/subscription-card";
@import "agconnect-new/components/standard-features";
@import "agconnect-new/components/membership-type-block";
@import "agconnect-new/components/membership-type";
@import "agconnect-new/components/group-label";
@import "agconnect-new/components/select-account";
@import "agconnect-new/components/membership-builder";
@import "agconnect-new/components/pill";
@import "agconnect-new/components/inputs/index";

@import url("https://use.typekit.net/odv4puy.css");

/* Use this file for hotfixes and refactor later */
@import "agconnect-new/shame";
