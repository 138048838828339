.c-select-account {
  background: $color-white;
  padding: 2.5rem 2.5rem;
  border: 1px solid $color-grey--lighter;
}

.c-select-account__header {
  font-size: 2rem;
  text-align: center;
}

.c-select-account__section {
  color: $color-grey--lighter;
  padding: 2em 0 1em 0;
  border-bottom: 1px solid $color-grey--lighter;
  font-size: 1.5rem;
}

.c-select-account__item {
  border-bottom: 1px solid $color-grey--lighter;
  position: relative;
  transition: $default-transition;
}

.c-select-account__item:hover {
  background-color: rgba(0,0,0,0.05);
  color: inherit;

  a {
    text-decoration: none;
  }
}

.c-select-account__item__continue {
  padding: 1em 0;
}

.c-select-account__name {
  font-weight: bold;
  display: block;
}

.c-select-account__email {
  display: block;
}

.c-select-account__profile-image {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  float: left;
  margin: 0 1em;
}

.c-select-account__link {
  display: block;
  color: $color-black;
  position: relative;
  text-decoration: none;
  padding: 1em 0;
}

.c-select-account__username {
  line-height: 48px;
}

.c-select-account__link i {
  display: inline-block;
  width: 48px;
  margin: 0 1em;
  text-align: center
}

.c-select-account__submit {
  width: 100%;
  height: 100%;
  display: block;
  background-color: transparent;
  position: absolute;
  top: 0;
  cursor: pointer;
}
