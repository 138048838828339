.c-flash-message {
  padding: 1rem 2rem;
  border: 1px solid $color-black;
  margin-bottom: 3rem;
}

.c-flash-message--error {
  border-color: transparent;
  background: lighten($color-red, 20);
  
  p {
    color: $color-black;
  }
}

.c-flash-message--success {
  border-color: $color-green;
  color: $color-green;
  background: $color-white;
}
