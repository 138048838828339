.c-external-services {
    display: flex;

    .c-button {
        flex-grow: 1;
        text-align: center;
        margin-right: 1rem;
    }

    .c-button:last-child {
        margin-right: 0;
    }
}
