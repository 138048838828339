.c-pricing-option {
  margin-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    height: 100%;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: row; // IE 11
  }
}

.c-pricing-option.is-selected {
  .c-pricing-option__content {
    border: 2px solid $color-green;
  }

  .c-pricing-option__cta {
    display: none;
  }

  .c-pricing-option__chosen {
    display: block;
  }
}

.c-pricing-option__content {
  position: relative;
  background: $color-white;
  border: 1px solid $color-grey--lighter;
  padding: 1.5rem 3rem 2rem;

  @include media-breakpoint-up(sm) {
    padding: 2rem 2rem 8rem;
    flex: 1;
  }
}

.c-pricing-option__name,
.c-pricing-option__specific,
.c-pricing-option-condition,
.c-pricing-option__chosen {
  text-align: center;
}

.c-pricing-option__name {
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}

.c-pricing-option__price {
  margin-bottom: 2.5rem;
  text-align: center;
}

.c-pricing-option__specific {
  height: 3rem;
  margin-bottom: 2rem;
}

.c-pricing-option__label {
}

.c-pricing-option__includes {
  margin-bottom: 2rem;
}

.c-pricing-option__include {
  @extend p;
  position: relative;
  margin-bottom: 1rem;
  padding: 0 0 1rem 3rem;
  border-bottom: 1px solid $color-grey--lighter;

  &:before {
    @extend %icon;
    content: icon-char(checkmark);
    color: $color-green;
    position: absolute;
    top: 0.6rem;
    left: 1rem;
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(sm) {
    &:before {
      top: 1.2rem;
    }
  }
}

.c-pricing-option__cta {
  width: 100%;
}

.c-pricing-option__footer {
  @include media-breakpoint-up(sm) {
    position: absolute;
    bottom: 3rem;
    left: 2rem;
    right: 2rem;
  }
}

.c-pricing-option-condition {
  margin: 1rem auto 2rem;
  font-family: $ff-monospace;
  color: $color-grey--light;
  font-size: map_get($fs--small, xs);
  line-height: map_get($lh--small, xs);

  @include media-breakpoint-up(sm) {
    font-size: map_get($fs--small, sm);
    line-height: map_get($lh--small, sm);
  }
}

.c-pricing-option__chosen {
  display: none;
  line-height: 4.5rem;
  color: $color-green;
}
