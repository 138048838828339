.c-toggle-slider {
    display: flex;
    
    input {
        opacity: 0;
        height: 0;
        width: 0;
    }

    label {
        display: flex;
        margin-bottom: 0;
        cursor: pointer;
    }
}

.c-toggle-slider--pill {
    input {   
        &:checked + label {
            .c-toggle-slider__dot {
                transform: translate(20px, -50%);
            }

            span:first-of-type {
                color: $color-grey;
            }
            
            span:last-of-type {
                color: $color-black;
            }
        }

        &:not(checked) + label {
            span:first-of-type {
                color: $color-black;
            }
            
            span:last-of-type {
                color: $color-grey;
            }
        }
    }
    
    span {
        font-size: 1.8rem;
        transition: color 300ms;
    }

    .c-toggle-slider__slider {
        position: relative;
        width: 4rem;
        height: 2rem;
        border: solid 1px $color-grey--dark;
        border-radius: 2rem;
        margin: 0 0.75rem;
    }

    .c-toggle-slider__dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 300ms ease;
        left: -4px;
        height: 2.6rem;
        width: 2.6rem;
        border-radius: 50%;
        background-color: $color-blue;
    }
}

.c-toggle-slider--box {
    position: relative;

    input {
        &:checked + label {
            span:first-of-type {
                color: $color-white;

                &:hover {
                    background-color: #00aae340;
                }
            }

            span:last-of-type {
                color: $color-blue--light;
            }

            .c-toggle-slider__slider {
                transform: translateX(100%);
            }

        }

        &:not(:checked) + label {
            span:first-of-type {
                color: $color-blue--light;
            }
            
            span:last-of-type {
                color: $color-white;

                &:hover {
                    background-color: #00aae340;
                }
            }
        }
    }

    label {
        width: 28rem;
        height: 4rem;
        border: 2px solid $color-white;
        font-family: $ff-serif;
        font-size: 2.4rem;
        font-weight: 600;
        text-align: center;
        transition: background-color 300ms;
    }

    span {
        width: 50%;
        height: 3.6rem;
        line-height: 3.6rem;
        z-index: 1;
        transition: all 300ms ease;
    }

    .c-toggle-slider__slider {
        position: absolute;
        z-index: 0;
        height: 100%;
        width: calc(50% - 2px);
        top: 0;
        background-color: $color-white;
        transition: transform 300ms ease;
    }
}
