.c-subscription-info {
  border-top: 1px solid $color-grey--lighter;
  padding: 1em 0;

  &:last-child {
    padding: 1em 0 0;
  }

  ul {
    margin: 0 0 1em;

    &:last-child {
      margin: 0;
    }

    li {
      margin-bottom: .2em;

      &:last-child {
        margin: 0;
      }
    }
  }
}
