@mixin checkboxAndRadioButtonBase() {
    $self: &;
    display: block;

    &__input {
        display: inline;
        appearance: none;
        margin: 0;
    }

    &__label {
        @include mb(6);
        @include label(lg);
        position: relative;
        width: max-content;
        height: spacer(5);
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            @include mb(5);
            height: spacer(6);
        }

        fieldset > & {
            @include mb(5);

            @include media-breakpoint-up(md) {
                @include mb(3);
            }
        }

        &::before {
            content: '';
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-right: spacer(3);
            box-shadow: 2px 2px $c-support;
            background-color: $c-white;
            background-repeat: no-repeat;
            background-position: center;
            flex-shrink: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        #{$self}__input:not(:disabled) + & {
            cursor: pointer;
        }

        #{$self}__input:disabled + & {
            color: $c-support;

            &::before {
                background-color: $c-disabled;
            }
        }

        #{$self}__input:checked:disabled + & {
            &::before {
                background-color: $c-disabled;
            }
        }
    }
}

@mixin rectangularInputBase() {
    $self: &;
    @include mb(6);

    @include media-breakpoint-up(md) {
        @include mb(5);
    }

    &__label {
        @include label(lg);
        @include mb(1);
        display: inline-block;
        color: $c-text;
    }

    &__input {
        $shadow-offset: 2px;
        --shadow-color: transparent;
        @include paragraph(sm);
        @include pl(2);
        @include pr(3);
        margin-bottom: $shadow-offset;
        margin-right: $shadow-offset;
        width: 100%;
        height: 4.4rem;
        color: $c-text;
        background-color: $c-white;
        border: none;
        outline: none;
        box-shadow: $shadow-offset $shadow-offset var(--shadow-color);

        &::placeholder {
            color: $c-disabled;
        }

        &:focus,
        &:active {
            --shadow-color: #{$c-support};
        }

        #{$self}--error & {
            --shadow-color: #{$c-error};
        }

        &:disabled {
            --shadow-color: transparent;
            color: $c-support;
            background-color: $c-disabled;
        }
    }

    &__messages {
        @include mt(1);

        li {
            @include label(lg);

            @include media-breakpoint-up(md) {
                @include label(md);
            }
        }

        #{$self}--error & {
            color: $c-error;
        }
    }
}
