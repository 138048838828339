.c-step-indicator {
  border-bottom: 1px solid $color-grey--light;
  background: $color-white;
  position: sticky;
  top: 0;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-step-indicator__step__text {
  width: 100%;
  background-color: $c-white;
  font-size: $fs;
  color: $c-black;
}

.c-step-indicator__content {
  background-color: $color-grey--dark;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &::before {
    content: '';
    background-color: $c-main;
    height: 6px;
    width: 100%;
    display: block;
    flex: 5;
  }

  &::after {
    content: '';
    background-color: $color-grey--dark;
    height: 6px;
    width: 100%;
    display: block;
    flex: 1;
  }
}

.c-step-indicator__step {
  flex: 5;
  height: 6px;

  .c-step__icon, .c-step__text {
    display: none !important;
  }

  a::before {
    background-color: $c-main;
    content: '';
    width: 0;
    height: 100%;
    transition: width 1.5s ease-in-out;
  }

  .is-active, .is-completed {
    &::before {
      width: 100%;
    }

    width: 100%;
    display: flex;
    height: 6px;
  }
}
