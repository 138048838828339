/**
* @file
* Basic styling for form labels
*
* @example
* <label class="c-form-label" for="input-field">Label for field</label>
 */

.c-form-label {
  display: block;
  font-size: $fs--label;
  line-height: $lh--label;
  margin-bottom: 5px;

  &.required::after {
    content: '*';
  }
}
